<template>
  <div class="my-10 lg:my-0 dark:bg-gray-900">
    <div class="container mx-auto">
      <div class="row">
        <div class="col-md-4 space-y-2 pt-20">
          <h4 class="text-xl">Your Fundraiser Campaign dashboard</h4>
          <div class="text-center">
            <a
              href="/fund-options"
              class="px-4 py-2 bg-oonpayprimary text-white"
              >Start A New Fundraiser Here</a
            >
          </div>
        </div>
        <div
          class="
            col-md-4
            flex
            items-center
            justify-center
            flex-col
            space-y-5
            pt-10
          "
        >
          <img
            src="../../../assets/icons/Oonpay logo.png"
            class="img-fluid"
            height="80px"
            width="80px"
            alt=""
          />
          <h3 class="text-oonpaysecondary mb-5">
            Crowdfund<span class="text-oonpayprimary">hub</span>
          </h3>
        </div>
        <div class="col-md-4 py-4 mobile">
          <div class="flex justify-evenly">
            <span>Robert Obiri</span>
            <button class="btn shadow-none" type="button" @click="toggle = 0">
              <span
                class="iconify"
                data-icon="simple-line-icons:arrow-up"
                data-inline="false"
              ></span>
            </button>
            <button class="btn shadow-none" type="button" @click="toggle = 1">
              <span
                class="iconify"
                data-icon="simple-line-icons:arrow-down"
                data-inline="false"
              ></span>
            </button>
          </div>
          <div
            v-if="toggle == 1"
            class="flex flex-col p-2 bg-gray-100 items-end"
          >
            <div class="font-normal text-sm">
              <p>Funding Campaign dashboard</p>
              <p>Funds Raised By You</p>
              <p>Account Settings</p>
              <p>Help Center</p>
              <p>Start A New fund Campaign</p>
              <p>Signout</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="campaigns == 1">
        <div class="row py-10">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <h5 class="text-center font-bold">
                  Support Maya Recovery Today
                </h5>
                <img src="https://dummyimage.com/600x400" alt="" />
              </div>
              <div class="col-md-6">
                <p><span class="font-bold">Campaign Period</span> (30 days)</p>
                <p><span class="font-bold">Started</span> August 9th 2021</p>
                <p class="font-bold text-oonpaysecondary">
                  Funding Goal $100000
                </p>
                <p class="font-bold text-oonpaysecondary">
                  Total Funds Raised $0
                </p>
                <div class="flex items-center">
                  <span
                    class="iconify"
                    data-icon="mdi:pencil"
                    data-inline="false"
                  ></span>
                  <span class="ml-2">Edit & Settings</span>
                </div>
                <div class="flex items-center">
                  <span
                    class="iconify"
                    data-icon="mdi:eye"
                    data-inline="false"
                  ></span>
                  <span class="ml-2">View Fund Raise Page</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 text-center">
                <small>#FUNDTAG-QR-CODE</small>
                <img src="https://dummyimage.com/200x180" alt="" />
                <button class="btn btn-primary my-2">share</button>
              </div>
              <div class="col-md-4 space-y-5">
                <small>Download QR-Code</small>

                <div class="form-check">
                  <input type="checkbox" class="form-check-input" />
                  <label class="form-check-label" for="png">png</label>
                </div>

                <div class="form-check">
                  <input type="checkbox" class="form-check-input" />
                  <label class="form-check-label" for="png">jpeg</label>
                </div>

                <div class="form-check">
                  <input type="checkbox" class="form-check-input" />
                  <label class="form-check-label" for="png">pdf</label>
                </div>

                <div class="mt-4">
                  <button class="btn btn-sm btn-primary">Download</button>
                </div>
              </div>
              <div class="col-md-4 space-y-28">
                <div>
                  <div class="text-primary">
                    <i class="fa fa-wrench" aria-hidden="true"></i>

                    <span class="ml-2">Update</span>
                  </div>
                  <div class="flex items-center mt-4">
                    <span
                      class="iconify"
                      data-icon="fa-solid:house-user"
                      data-inline="false"
                    ></span>
                    <span class="ml-2 text-primary">withdraw Funds</span>
                  </div>
                </div>

                <div class="flex items-center">
                  <span
                    class="iconify"
                    data-icon="mdi:delete-forever"
                    data-inline="false"
                  ></span>
                  <span class="ml-2 text-primary">Delete Campaign</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row py-10">
          <div class="col-md-3">
            <p class="text-oonpaysecondary text-xl">Send invitation Now</p>
          </div>
          <div class="col-md-3">
            <button class="btn btn-outline-dark btn-sm">Send Email</button>
          </div>
          <div class="col-md-3">
            <button class="btn btn-outline-dark btn-sm">Send Text</button>
          </div>
        </div>

        <div class="row pt-10">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-3 flex flex-col items-center space-y-5">
                <p>Campaign Validator</p>
                <span
                  class="iconify"
                  data-icon="icon-park-outline:every-user"
                  data-inline="false"
                ></span>
                <router-link to="/add-validator" class="btn btn-primary btn-sm"
                  >Add Validator</router-link
                >
              </div>
              <div class="col-md-6">
                <div class="flex mt-4 space-x-5">
                  <div class="flex flex-col items-center">
                    <img
                      src="https://www.w3schools.com/howto/img_avatar2.png"
                      alt="Avatar"
                      class="avatar"
                    />
                    <span>Eugenia Ofosu</span>
                    <span>eu@gmail.com</span>
                  </div>

                  <div class="flex flex-col items-center">
                    <img
                      src="https://www.w3schools.com/w3images/avatar6.png"
                      alt="Avatar"
                      class="avatar"
                    />
                    <span>Eric Asante</span>
                    <span>eric@gmail.com</span>
                  </div>

                  <div class="flex flex-col items-center">
                    <img
                      src="https://www.w3schools.com/howto/img_avatar.png"
                      alt="Avatar"
                      class="avatar"
                    />
                    <span>Emmanuel Ofosu</span>
                    <span>emma@gmail.com</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-center">
                <P class="text-primary">Validation Status</P>
                <div class="flex justify-center">
                  <p class="mr-2">verified</p>
                  <span
                    class="iconify"
                    data-icon="ic:baseline-check"
                    data-inline="false"
                  ></span>
                </div>

                <div class="flex justify-center">
                  <p class="mr-2">Pending</p>
                  <span
                    class="iconify"
                    data-icon="clarity:eye-show-solid"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h4>Why Add Validator</h4>
            <ul>
              <li>Validator Help Your Donors Autenticate the fund drive</li>
              <li>Validator should be a minimum of 2 persons</li>
              <li>
                The more validator you add the greater the the trust from your
                donors
              </li>
              <li>Validator should be oonpay app users</li>
              <li>
                Validator could be organisation offering serivces to the
                fund-direct benefactor
              </li>
              <li>
                Validator could lend a voice support your fund raise campaign
              </li>
            </ul>
          </div>
        </div>

        <div class="row pb-10">
          <div class="col-md-8">
            <nav class="my-4">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  class="nav-link active"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  >Campaign Donors</a
                >
                <a
                  class="nav-link"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  >Updates</a
                >
                <a
                  class="nav-link"
                  id="nav-contact-tab"
                  data-toggle="tab"
                  href="#nav-contact"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                  >Team</a
                >
                <a
                  class="nav-link"
                  id="nav-notification-tab"
                  data-toggle="tab"
                  href="#nav-notification"
                  role="tab"
                  aria-controls="nav-notification"
                  aria-selected="false"
                  >Notifications</a
                >
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim
                irure officia enim reprehenderit. Magna duis labore cillum sint
                adipisicing exercitation ipsum. Nostrud ut anim non exercitation
                velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
                velit ullamco magna consequat voluptate minim amet aliquip ipsum
                aute laboris nisi. Labore labore veniam irure irure ipsum
                pariatur mollit magna in cupidatat dolore magna irure esse
                tempor ad mollit. Dolore commodo nulla minim amet ipsum officia
                consectetur amet ullamco voluptate nisi commodo ea sit eu.
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim
                irure officia enim reprehenderit. Magna duis labore cillum sint
                adipisicing exercitation ipsum. Nostrud ut anim non exercitation
                velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
                velit ullamco magna consequat voluptate minim amet aliquip ipsum
                aute laboris nisi. Labore labore veniam irure irure ipsum
                pariatur mollit magna in cupidatat dolore magna irure esse
                tempor ad mollit. Dolore commodo nulla minim amet ipsum officia
                consectetur amet ullamco voluptate nisi commodo ea sit eu.
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim
                irure officia enim reprehenderit. Magna duis labore cillum sint
                adipisicing exercitation ipsum. Nostrud ut anim non exercitation
                velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
                velit ullamco magna consequat voluptate minim amet aliquip ipsum
                aute laboris nisi. Labore labore veniam irure irure ipsum
                pariatur mollit magna in cupidatat dolore magna irure esse
                tempor ad mollit. Dolore commodo nulla minim amet ipsum officia
                consectetur amet ullamco voluptate nisi commodo ea sit eu.
              </div>

              <div
                class="tab-pane fade"
                id="nav-notification"
                role="tabpanel"
                aria-labelledby="nav-notification-tab"
              >
                Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim
                irure officia enim reprehenderit. Magna duis labore cillum sint
                adipisicing exercitation ipsum. Nostrud ut anim non exercitation
                velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
                velit ullamco magna consequat voluptate minim amet aliquip ipsum
                aute laboris nisi. Labore labore veniam irure irure ipsum
                pariatur mollit magna in cupidatat dolore magna irure esse
                tempor ad mollit. Dolore commodo nulla minim amet ipsum officia
                consectetur amet ullamco voluptate nisi commodo ea sit eu.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row flex justify-center">
          <div class="col-md-8 flex flex-col items-center space-y-5">
            <img
              src="../../../assets/icons/crowdfunding_fund_funds_growth_invest_icon.png"
              class="img-fluid"
              height="80px"
              width="80px"
              alt=""
            />
            <small class="font-black">No Fund Raiser Compaigns Yet</small>
            <small>Your Fund Raiser Campaigns Will Appear Here</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: 0,
      campaigns: 1,
    };
  },
  methods: {
    open() {
      return (this.toggle = 1);
    },
  },
};
</script>
<style  scoped>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.iconify {
  font-size: 20px;
}

.iconify[data-icon="mdi:delete-forever"] {
  color: red;
}

.iconify[data-icon="fa-solid:house-user"] {
  color: green;
}

.iconify[data-icon="icon-park-outline:every-user"] {
  font-size: 38px;
}

@media (max-width: 640px) {
  .mobile {
    display: flex;
    order: 1;
  }
}
</style>